import React from "react";
import './App.css';

function App() {
  return (
    <div className="container">
      <div className="content-container">
      This site is private
    </div>
    </div>
  );
}

export default App;
